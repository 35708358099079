<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      Copyright {{ new Date().getFullYear() }}
      <b-link class="ml-25" href="https://waltlabs.io/" target="_blank"
        >WALTLabs.io</b-link
      >
      <span class="d-none d-sm-inline-block">, LLC. All rights reserved.</span>
    </span>
  </p>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
};
</script>
