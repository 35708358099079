<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.first_name + " " + userData.last_name }}
        </p>
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon v-if="!userData.first_name" icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
      <span>Settings</span>
    </b-dropdown-item>

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown
  >
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import store from "@/store";
import axios from "@axios";
export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      avatarText,
    };
  },
  methods: {
    logout() {
      const token = localStorage.getItem("accessToken");
      let socket = this.$socket;

      axios
        .get(`/auth/logout`, {
          headers: {
            Authorization: "Bearer " + token, //the token is a variable which holds the token
          },
          params: { uid: this.userData.id },
        })
        .then(() => {
          socket.disconnect();

          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
          localStorage.removeItem("notificationCount");
          localStorage.removeItem("notificationData");
          localStorage.removeItem("securityanalysisData");
          localStorage.removeItem("securityanalysisDatabaseNameIndex");
          // Remove userData from localStorage
          localStorage.removeItem("userData");
          localStorage.removeItem("regionCurrencyData");
          store.commit("app/USER_DATA", {});
          store.commit("app/CURRENCY_TYPE", "");
          // Reset ability
          this.$ability.update(initialAbility);

          // Redirect to login page
          this.$router.push({ name: "auth-login" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
