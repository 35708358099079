var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-nav-item-dropdown',{staticClass:"dropdown-notification mr-25",attrs:{"menu-class":"dropdown-menu-media","right":"","disabled":_vm.dropdown},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body",attrs:{"badge":_vm.notificationCount > 20 ? '20+' : _vm.notificationCount,"badge-classes":"bg-danger","icon":"BellIcon","size":"21"},on:{"click":_vm.getNotification}})]},proxy:true}])},[_c('li',{staticClass:"dropdown-menu-header"},[_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-start"},[_c('h4',{staticClass:"notification-title m-1"},[_vm._v("Notifications")])])],1)],1),_c('vue-perfect-scrollbar',{staticClass:"scrollable-container media-list scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"li"},on:{"click":function (e) {
        e.stopPropagation();
      }}},_vm._l((_vm.notifications),function(notification){return _c('li',{key:notification.id},[_c('b-media',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-1",modifiers:{"collapse-1":true}}],on:{"click":function($event){return _vm.data(notification.notification_id)}},scopedSlots:_vm._u([{key:"aside",fn:function(){return [(notification.type == 'User Notification')?_c('feather-icon',{staticClass:"iconStyle",attrs:{"icon":"UserIcon","size":"21"}}):_vm._e(),(notification.type == 'test notification')?_c('feather-icon',{staticClass:"iconStyle",attrs:{"icon":"BriefcaseIcon","size":"21"}}):_vm._e(),(notification.type == 'Jira Service Desk')?_c('feather-icon',{staticClass:"iconStyle",attrs:{"icon":"BookIcon","size":"21"}}):_vm._e(),(
              notification.type !== 'User Notification' &&
              notification.type !== 'Jira Service Desk' &&
              notification.type !== 'test notification'
            )?_c('feather-icon',{staticClass:"iconStyle",attrs:{"icon":"BellIcon","size":"21"}}):_vm._e(),(notification.type == '')?_c('feather-icon',{staticClass:"iconStyle",attrs:{"icon":"BellIcon","size":"21"}}):_vm._e(),(_vm.hasName == false)?_c('feather-icon',{staticClass:"iconStyle",attrs:{"icon":"BellIcon","size":"21"}}):_vm._e()]},proxy:true}],null,true)},[(notification.title)?_c('p',{staticClass:"media-heading"},[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(notification.title)+" ")])]):_vm._e(),(
            notification.description && notification.notification_id != _vm.expand
          )?_c('small',{staticClass:"notification-text"},[_vm._v(_vm._s(notification.description.length > 50 ? notification.description.substring(0, 50) + "..." : notification.description))]):_vm._e()]),(notification.notification_id == _vm.expand)?_c('b-collapse',{staticClass:"pl-4 pr-2",attrs:{"id":"collapse-1"}},[(notification.description)?_c('small',{staticClass:"d-flex justify-content-left"},[_vm._v(_vm._s(notification.description))]):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-between"},[(notification.created_at)?_c('small',[_vm._v(_vm._s(notification.created_at.slice(0, 10)))]):_vm._e()]),_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('b-link',{on:{"click":function($event){return _vm.viewGcpIncident(notification.url, notification.type)}}},[_c('small',[_vm._v("Reference")])])],1)],1)],1):_vm._e()],1)}),0),(_vm.notificationCount == 0)?_c('p',{staticClass:"font-weight-bolder mt-1 d-flex align-item-center justify-content-center"},[_vm._v(" No New Notification ")]):_vm._e(),_c('div',{staticClass:"d-flex justify-content-between align-items-between dropdown-menu-footer"},[(_vm.notificationCount >= 1)?_c('li',[_c('b-link',{on:{"click":function($event){return _vm.resetNotificationCount(_vm.notifications)}}},[_vm._v("Mark all as Read")])],1):_vm._e(),_c('b-link',{on:{"click":_vm.routeNotification}},[_vm._v("All Notifications")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }