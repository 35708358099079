<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
    :disabled="dropdown"
  >
    <template #button-content>
      <feather-icon
        :badge="notificationCount > 20 ? '20+' : notificationCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
        @click="getNotification"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <b-row>
        <b-col class="d-flex justify-content-start">
          <h4 class="notification-title m-1">Notifications</h4>
        </b-col>
      </b-row>
    </li>
    <!-- Notifications -->
    <vue-perfect-scrollbar
      @click="
        (e) => {
          e.stopPropagation();
        }
      "
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Latest GCP Notification -->
      <li v-for="notification in notifications" :key="notification.id">
        <b-media
          v-b-toggle.collapse-1
          @click="data(notification.notification_id)"
        >
          <template #aside>
            <feather-icon
              class="iconStyle"
              icon="UserIcon"
              v-if="notification.type == 'User Notification'"
              size="21"
            />
            <feather-icon
              class="iconStyle"
              icon="BriefcaseIcon"
              v-if="notification.type == 'test notification'"
              size="21"
            />
            <feather-icon
              class="iconStyle"
              icon="BookIcon"
              v-if="notification.type == 'Jira Service Desk'"
              size="21"
            />
            <feather-icon
              class="iconStyle"
              icon="BellIcon"
              v-if="
                notification.type !== 'User Notification' &&
                notification.type !== 'Jira Service Desk' &&
                notification.type !== 'test notification'
              "
              size="21"
            />
            <feather-icon
              class="iconStyle"
              icon="BellIcon"
              v-if="notification.type == ''"
              size="21"
            />
            <feather-icon
              class="iconStyle"
              icon="BellIcon"
              v-if="hasName == false"
              size="21"
            />
          </template>
          <p class="media-heading" v-if="notification.title">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small
            class="notification-text"
            v-if="
              notification.description && notification.notification_id != expand
            "
            >{{
              notification.description.length > 50
                ? notification.description.substring(0, 50) + "..."
                : notification.description
            }}</small
          >
        </b-media>
        <b-collapse
          v-if="notification.notification_id == expand"
          id="collapse-1"
          class="pl-4 pr-2"
        >
          <small
            v-if="notification.description"
            class="d-flex justify-content-left"
            >{{ notification.description }}</small
          >
          <b-row>
            <b-col class="d-flex justify-content-between">
              <small v-if="notification.created_at">{{
                notification.created_at.slice(0, 10)
              }}</small>
            </b-col>
            <b-col class="d-flex justify-content-end">
              <b-link
                @click="viewGcpIncident(notification.url, notification.type)"
                ><small>Reference</small></b-link
              >
            </b-col>
          </b-row>
        </b-collapse>
      </li>
    </vue-perfect-scrollbar>
    <p
      class="font-weight-bolder mt-1 d-flex align-item-center justify-content-center"
      v-if="notificationCount == 0"
    >
      No New Notification
    </p>
    <!-- Cart Footer -->
    <div
      class="d-flex justify-content-between align-items-between dropdown-menu-footer"
    >
      <li v-if="notificationCount >= 1">
        <b-link @click="resetNotificationCount(notifications)"
          >Mark all as Read</b-link
        >
      </li>
      <b-link @click="routeNotification">All Notifications</b-link>
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BRow,
  BCol,
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
  BCollapse,
  BCard,
  VBToggle,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";
import { io } from "socket.io-client";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import axios from "@axios";
import Vue from "vue";

export default {
  components: {
    BRow,
    BCol,
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    BCollapse,
    BCard,
    VBToggle,
  },
  directives: {
    Ripple,
  },
  computed: {
    hasName() {
      for (const [key, value] of Object.entries(this.notifications)) {
        return Object.keys(value).includes("type");
      }
    },
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {};
  },
  methods: {
    routeNotification() {
      this.$router.push("/notification");
      this.getAllNotifications();
    },
    viewGcpIncident(url, type) {
      if (type == "GCP_INCIDENT") {
        window.open(url);
      } else if (type == "USER_MANAGEMENT") {
        window.open(
          process.env.VUE_APP_NOTIFICATION_REFERENCE_URL +
            "/apps/users/view/" +
            url
        );
      } else if (type == "JIRA_SERVICE_DESK") {
        window.open(
          process.env.VUE_APP_NOTIFICATION_REFERENCE_URL +
            "/apps/support-cases/view/" +
            url
        );
      }
    },
    data(id) {
      if (id == this.expand) {
        this.expand = 0;
        for (const [notificationKey, notificationValue] of Object.entries(
          this.notifications
        )) {
          if (notificationValue.notification_id == id) {
            this.notifications.splice(notificationKey, 1);
            localStorage.setItem(
              "notificationData",
              JSON.stringify(this.notifications)
            );
            this.notificationCount = this.notificationCount - 1;
            localStorage.setItem(
              "notificationCount",
              JSON.stringify(this.notificationCount)
            );
          }
        }
      } else {
        this.expand = id;
        axios
          .put(`/notifications/user/${this.userData.id}`, {
            headers: {
              Authorization: "Bearer " + this.token, //the token is a variable which holds the token
            },
            notification_ids: [id],
            is_read: true,
          })
          .then((response) => {
            if (response) {
              this.dropdown = false;
            }
          })
          .catch((err) => {
            this.dropdown = false;
            console.log(err);
          });
      }
    },
  },
  setup() {
    let notification_id = ref([]);
    let messageData = ref([]);
    let notifications = ref([]);
    let notificationCount = ref(0);
    let dropdown = ref(false);
    let expand = ref(0);
    const toast = useToast();
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("accessToken");

    const getAllNotifications = () => {
      dropdown.value = true;
      notificationCount.value = 0;
      axios
        .get(`/notifications/user/${userData.id}`, {
          headers: {
            Authorization: "Bearer " + token, //the token is a variable which holds the token
          },
          params: {
            is_read: false,
          },
        })
        .then((response) => {
          dropdown.value = false;
          if (response.data.items.length == 0) {
            localStorage.setItem("notificationCount", JSON.stringify(0));
          }
          notifications.value = response.data.items;
          localStorage.setItem(
            "notificationData",
            JSON.stringify(notifications.value)
          );
          for (const [notificationKey, notificationValue] of Object.entries(
            response.data.items
          )) {
            notificationCount.value = notificationCount.value + 1;
            localStorage.setItem(
              "notificationCount",
              JSON.stringify(notificationCount.value)
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getAllNotifications();

    const getNotification = () => {
      notifications.value = JSON.parse(
        localStorage.getItem("notificationData")
      );
    };

    const notificationGetCount = () => {
      notificationCount.value = JSON.parse(
        localStorage.getItem("notificationCount")
      );
      return notificationCount.value;
    };

    const resetNotificationCount = (notifications) => {
      dropdown.value = true;
      for (const [notificationKey, notificationValue] of Object.entries(
        notifications
      )) {
        if (!notificationValue.is_read) {
          notification_id.value.push(notificationValue.notification_id);
        }
      }
      axios
        .put(`/notifications/user/${userData.id}`, {
          headers: {
            Authorization: "Bearer " + token, //the token is a variable which holds the token
          },
          notification_ids: notification_id.value,
          is_read: true,
        })
        .then((response) => {
          if (response) {
            notifications.value = [];
            localStorage.setItem(
              "notificationData",
              JSON.stringify(notifications.value)
            );

            notificationCount.value = 0;
            localStorage.setItem(
              "notificationCount",
              JSON.stringify(notificationCount.value)
            );
            dropdown.value = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const socket = io(process.env.VUE_APP_NOTIFICATION_URL, {
      path: "/socketio/open_connection",
      query: { auth_token: JSON.parse(localStorage.getItem("accessToken")) },
      extraHeaders: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });

    socket.on("connect", () => {
      Vue.prototype.$socket = socket;
    });

    socket.on("message", (data) => {
      let notificationArray = [];
      let notificationArrayCount = 0;
      if (data) {
        notificationArray = JSON.parse(
          localStorage.getItem("notificationData")
        );
        notificationArrayCount = JSON.parse(
          localStorage.getItem("notificationCount")
        );
        if (data.target_module !== "SECURITY_ANALYSIS") {
          notificationArray.unshift(data);
          notificationArrayCount = notificationArrayCount + 1;
          localStorage.setItem(
            "notificationData",
            JSON.stringify(notificationArray)
          );
          localStorage.setItem(
            "notificationCount",
            JSON.stringify(notificationArrayCount)
          );
          notificationGetCount();
        }

        toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: data.title !== "" ? data.title : "New Notification",
            icon: "BellIcon",
            variant: "primary",
            text:
              data.description !== ""
                ? data.description
                : "You have a new notification",
          },
        });
      }
    });

    socket.on("connect_error", (err) => {
      if (err?.description === 401) {
        socket.io.opts.query.auth_token = JSON.parse(
          localStorage.getItem("accessToken")
        );
        socket.connect();
      }
    });

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    return {
      notifications,
      notificationCount,
      perfectScrollbarSettings,
      getNotification,
      resetNotificationCount,
      messageData,
      getAllNotifications,
      dropdown,
      expand,
      token,
      userData,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";

.iconStyle {
  margin-top: 0.5rem;
}

#__BVID__56
  > ul
  > li.ps-container.scrollable-container.media-list.scroll-area.ps
  > li
  > div {
  border-bottom: 0px;
}

#__BVID__56
  > ul
  > li.ps-container.scrollable-container.media-list.scroll-area.ps
  > li {
  border-bottom: 1px solid $border-color !important;
}
</style>
