<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered),
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link class="navbar-brand" to="/">
              <span class="brand-logo">
                <b-img
                  :src="skinChange == 'dark' ? appLogoImageDark : appLogoImage"
                  alt="logo"
                />
              </span>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="50"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        (evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0;
        }
      "
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
// import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BLink, BImg } from "bootstrap-vue";
import { provide, computed, ref } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "./useVerticalNavMenu";
import store from "@/store";

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  watch: {
    "$store.state.appConfig.layout.skin": {
      handler() {
        this.skinChange = store.state.appConfig.layout.skin;
      },
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide("isMouseHovered", isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === "unpinned" ? "CircleIcon" : "DiscIcon"
    );

    // App Name
    const { appName, appLogoImage, appLogoImageDark } = $themeConfig.app;
    const skinChange = ref(store.state.appConfig.layout.skin);

    const navMenuItems = ref([]);

    navMenuItems.value.push({
      title: "Home",
      icon: "HomeIcon",
      route: "dashboard-usa",
      visible: true,
    });

    if (
      store.state.app.userData.role_name == "GLOBAL_ADMIN" ||
      store.state.app.userData.role_name == "ACCOUNT_MANAGER"
    ) {
      navMenuItems.value.push({
        title: "Admin",
        icon: "UserIcon",
        children: [
          {
            title: "Organizations",
            route: "apps-organizations-list",
            visible:
              store.state.app.userData.role_name == "GLOBAL_ADMIN" ||
              store.state.app.userData.role_name == "ACCOUNT_MANAGER"
                ? true
                : false,
          },
          {
            title: "Users",
            route: "apps-users-list",
            visible:
              store.state.app.userData.role_name == "GLOBAL_ADMIN" ||
              store.state.app.userData.role_name == "ORG_ADMIN" ||
              store.state.app.userData.role_name == "ACCOUNT_MANAGER"
                ? true
                : false,
          },
        ],
      });
    } else if (
      store.state.app.userData.role_name == "ORG_ADMIN" &&
      store.state.app.userData.role_name !== "EMPLOYEE"
    )
      navMenuItems.value.push({
        title: "Admin",
        icon: "UserIcon",
        children: [
          {
            title: "Users",
            route: "apps-users-list",
            visible:
              store.state.app.userData.role_name == "GLOBAL_ADMIN" ||
              store.state.app.userData.role_name == "ORG_ADMIN" ||
              store.state.app.userData.role_name == "ACCOUNT_MANAGER"
                ? true
                : false,
          },
        ],
      });

    navMenuItems.value.push(
      {
        title: "Support",
        icon: "HeadphonesIcon",
        children: [
          // {
          //     title: "Overview",
          //     route: "apps-overview",
          //     visible: true,
          //   },
          {
            title: "Tickets",
            route: "apps-support-cases-list",
            visible: true,
          },
          {
            title: "AI Chatbot",
            route: "apps-chatgpt",
            visible: true,
          },
        ],
      },
      {
        title: "Monitoring",
        icon: "MonitorIcon",
        children: [
          {
            title: "GCP Services Status",
            route: "apps-gcp-helpers-list",
            visible: true,
          },
        ],
      },
      {
        title: "Security",
        icon: "ShieldIcon",
        children: [
          {
            title: "Scans",
            route: "app-scoutsuite",
            visible: true,
          },
        ],
      }
    );
    if (
      store.state.app.userData.role_name == "ACCOUNT_MANAGER" ||
      store.state.app.userData.role_name == "GLOBAL_ADMIN"
    ) {
      navMenuItems.value.push({
        title: "Reports",
        icon: "ClipboardIcon",
        children: [
          {
            title: "Discounts",
            route: "apps-discounts-report-list",
            visible: true,
          },
          {
            title: "Partner FinOps Overview",
            route: "dashboard-partner",
            visible: true,
          },
          // {
          //   title: "Anomalies",
          //   route: "apps-anomalies-report-list",
          //   visible: true,
          // },
        ],
        visible:
          store.state.app.userData.role_name == "ACCOUNT_MANAGER" ||
          store.state.app.userData.role_name == "GLOBAL_ADMIN"
            ? true
            : false,
      });
    } else {
      navMenuItems.value.push({
        title: "Reports",
        icon: "ClipboardIcon",
        children: [
          {
            title: "Discounts",
            route: "apps-discounts-report-list",
            visible: true,
          },
          // {
          //   title: "Anomalies",
          //   route: "apps-anomalies-report-list",
          //   visible: true,
          // },
        ],
        visible:
          store.state.app.userData.role_name == "EMPLOYEE" ||
          store.state.app.userData.role_name == "ORG_ADMIN"
            ? true
            : false,
      });
    }

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      appLogoImageDark,
      skinChange,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
